import styles from './kundendaten.module.scss';
import BooleanIcon from 'components/BooleanIcon/BooleanIcon';
import Layout from 'components/layout';
import Wrapper from 'components/Wrapper/Wrapper';
import StickyBar from 'components/StickyBar/StickyBar';
import api from 'api';

import useAuthStore from 'store/useAuth';
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from 'react';
import { navigate } from 'gatsby';
import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import Heading from '@wienenergiegithub/ui-next/src/common/components/Heading/Heading';
import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';

import InputField from '@wienenergiegithub/ui-next/src/common/components/InputField/InputField';
import Table from '@wienenergiegithub/ui-next/src/common/components/Table/Table';
import { useNotification } from '@wienenergiegithub/layout/src/common/modules/Notification/Context';
import Checkbox from '@wienenergiegithub/ui-next/src/common/components/Checkbox/Checkbox';
import Card from '@wienenergiegithub/ui-next/src/common/components/Card/Card';
import { Checkbox as ReakitCheckbox } from 'reakit/Checkbox';

import {
  unstable_useFormState as useFormState,
  unstable_Form as ReakitForm,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form';

import { usePopoverState, Popover, PopoverDisclosure } from 'reakit/Popover';

import { Tooltip, TooltipReference, useTooltipState } from 'reakit/Tooltip';
import classNames from 'classnames/index';

const weekDays = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];

const CustomerForm = ({ form }) => {
  return (
    <div className={styles.form}>
      <InputField name="name" label="Name" {...form} />
      <InputField name="long_name" label="Langname" {...form} />
      <InputField name="identifier" label="Kundennummer" {...form} />
      <InputField name="group" label="Gruppe" {...form} />
      <InputField name="people" label="Ansprechpartner E-Mail" {...form} />
      <InputField
        name="people_cc"
        label="CC E-Mails (mit Beistrich getrennt)"
        {...form}
      />
    </div>
  );
};

const AvailabilityPopover = ({
  facilityIndex,
  articleIndex,
  isRestrictedFacility,
  onWeekdayChange,
  facilityName,
  form,
}) => {
  const popover = usePopoverState({
    placement: 'bottom-start',
    unstable_preventOverflow: true,
  });

  const tooltip = useTooltipState();

  // if entire facility is restricted
  if (isRestrictedFacility) {
    return (
      <>
        <TooltipReference className={styles.popoverButton} {...tooltip}>
          {weekDays.map(wDay => (
            <BooleanIcon isTrue={false} key={wDay} />
          ))}
        </TooltipReference>
        <Tooltip {...tooltip} className={styles.tooltip}>
          Diese Einrichtung wurde für diese Produkt gesperrt
        </Tooltip>
      </>
    );
  }

  return (
    <>
      <PopoverDisclosure className={styles.popoverButton} {...popover}>
        {weekDays.map((wDay, index) => (
          <BooleanIcon
            isTrue={
              form.values?.articles?.[articleIndex]?.[facilityIndex]?.[
                `day-${index}`
              ]
            }
            key={wDay}
          />
        ))}
      </PopoverDisclosure>
      {popover.visible && (
        <Popover {...popover} aria-label="Welcome">
          <Card className={styles.popover} variant="elevated">
            <Heading size="h7" as="h5">
              {facilityName}
            </Heading>

            <div className={styles.checkBoxWrapper}>
              {weekDays.map(wDay => (
                <Heading
                  size="h7"
                  as="h5"
                  key={wDay}
                  className={styles.weekDayHeading}
                >
                  {wDay}
                </Heading>
              ))}

              {weekDays.map((itm, index) => (
                <ReakitCheckbox
                  as={Checkbox}
                  key={itm}
                  checked={
                    form.values?.articles?.[articleIndex]?.[facilityIndex]?.[
                      `day-${index}`
                    ]
                  }
                  onChange={onWeekdayChange([
                    'articles',
                    articleIndex,
                    facilityIndex,
                    `day-${index}`,
                  ])}
                />
              ))}
            </div>
          </Card>
        </Popover>
      )}
    </>
  );
};
const ArticleRow = ({
  onRowCheck,
  article,
  facilities,
  form,
  onWeekdayChange,
}) => {
  return useMemo(
    () => (
      <tr className={styles.row} key={article.id}>
        <td>
          <ReakitCheckbox
            as={Checkbox}
            checked={
              form?.values?.articles?.[`article-${article.id}`] &&
              Object.keys(form?.values?.articles?.[`article-${article.id}`])
                .length > 0
            }
            onChange={onRowCheck}
          />
        </td>
        <td>{article.ma48_id}</td>
        <td>{article.name}</td>
        <td>{article?.category?.internal_name}</td>
        <td>{article.bls_id}</td>
        {facilities.map(facility => (
          <td className={styles.facilityCell} key={facility.id}>
            <AvailabilityPopover
              form={form}
              facilityIndex={`facility-${facility.id}`}
              articleIndex={`article-${article.id}`}
              facilityName={facility.name}
              onWeekdayChange={onWeekdayChange}
              // isRestrictedFacility
            />
          </td>
        ))}
      </tr>
    ),
    [article, form.values?.articles?.[`article-${article.id}`]],
  );
};
const fullWeek = weekDays.reduce((prev, current, index) => {
  const acc = { ...prev };
  acc[`day-${index}`] = true;
  return acc;
}, {});

const ArticleTable = ({
  articles,
  form,
  facilities,
  hasStickyHeader,
  hasOnlyBody,
  stickyBarHeight,
}) => {
  const handleWeekdaychange = path => ({ currentTarget: { checked } }) => {
    form.update(path, checked);
  };

  const handleRowCheck = useCallback(
    articleId => () => {
      if (
        form?.values?.articles?.[`article-${articleId}`] &&
        Object.keys(form?.values?.articles?.[`article-${articleId}`]).length > 0
      ) {
        form.update(['articles', `article-${articleId}`], {});
        return;
      }

      form.update(
        [`articles`, `article-${articleId}`],
        facilities.reduce((prev, current) => {
          const acc = { ...prev };
          acc[`facility-${current.id}`] = { ...fullWeek };
          return acc;
        }, {}),
      );
    },
    [form.values],
  );
  return (
    <div
      className={
        hasStickyHeader ? styles.articlesStickyHeader : styles.articles
      }
      style={{ top: stickyBarHeight }}
    >
      <Table withParser={false}>
        <table>
          <thead
            className={classNames(
              styles.tableHeader,
              hasOnlyBody ? styles.stickyHeader : null,
            )}
          >
            <tr>
              <th>Verfügbar</th>
              <th>ID</th>
              <th>Bezeichnung</th>
              <th>Kategorie</th>
              <th>ID BLS</th>
              {facilities?.map(facility => (
                <th className={styles.facilityWrapper} key={facility.name}>
                  <div className={styles.facilityHeading}>{facility.name}</div>
                  <div className={styles.weekDayContainer}>
                    {weekDays.map(wDay => (
                      <div key={wDay} className={styles.weekDayHeading}>
                        {wDay}
                      </div>
                    ))}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={hasStickyHeader ? styles.stickyHeader : null}>
            {articles?.map(article => (
              <ArticleRow
                key={article.id}
                article={article}
                form={form}
                onWeekdayChange={handleWeekdaychange}
                onRowCheck={handleRowCheck(article.id)}
                facilities={facilities}
              />
            ))}
          </tbody>
        </table>
      </Table>
    </div>
  );
};

const createLaravelFormError = error => {
  const errors = {};
  if (!error?.response?.data) {
    throw errors;
  }
  Object.entries(error.response.data.errors).forEach(([key, val]) => {
    errors[key] = val.join(' ');
  }, {});

  throw errors;
};

const Page = () => {
  const stickyRef = useRef(null);
  const [customer, setCustomer] = useState();
  const [facilities, setFacilities] = useState();
  const [articles, setArticles] = useState(null);
  const notifications = useNotification();
  const [stickyBarHeight, setStickyBarHeight] = useState(0);

  const form = useFormState({
    values: {
      name: '',
      identifier: '',
      group: '',
      people: '',
    },
    onValidate: values => {
      let errors = {};
      if (!values.name) {
        errors = {
          ...errors,
          name: 'Name muss ausgefüllt sein',
        };
      }
      if (!values.identifier) {
        errors = {
          ...errors,
          identifier: 'Identifier muss ausgefüllt sein',
        };
      }
      if (!values.group) {
        errors = {
          ...errors,
          group: 'Gruppe muss ausgefüllt sein',
        };
      }

      if (!values.people) {
        errors = {
          ...errors,
          people: 'Ansprechpartner muss ausgefüllt sein',
        };
      }

      if (Object.keys(errors).length > 0) {
        throw errors;
      }
    },
    onSubmit: async values => {
      const id = (Math.random() + 1).toString(36).substring(7);

      try {
        const {
          data: { data },
        } = await api({
          url: `/backend/api/customers${customer?.id ? `/${customer.id}` : ''}`,
          method: customer?.id ? 'PUT' : 'POST',
          data: {
            ...values,
            group: values?.group,
            people: values?.people,
            people_cc: values?.people_cc?.split(','),
          },
        });
        // setArticles(articleData);
        setCustomer(data);

        // random id for notification popup
        notifications.add({
          title: 'Kunde gespeichert',
          icon: 'check',
          id,
          type: 'success',
          handleClose: () => {
            // eslint-disable-next-line no-console
            console.log('close');
          },
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 3000);

        if (customer?.id) {
          navigate('/kunden');
        }

        // if (!customer?.id) {
        //   navigate(`?customer=${customerData.id}`);
        // }
      } catch (error) {
        notifications.add({
          title: 'Es ist ein Fehler aufgetreten',
          details: 'Bitte versuchen Sie es später erneut',
          icon: 'exclamation',
          id,
          type: 'danger',
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 5000);
        // eslint-disable-next-line no-console
        console.error(error);
        createLaravelFormError(error);
      }
    },
  });

  useEffect(() => {
    const getCustomerData = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const customerId = params.get('customer');

        if (!customerId && !customer.id) {
          return;
        }
        const {
          data: {
            data: customerData,
            meta: { articles: articleData, articles_form: articlesForm = {} },
          },
        } = await api.get(
          `/backend/api/customers/${customerId || customer?.id}`,
        );
        setArticles(articleData);
        customerData.articles = articlesForm;

        Object.entries(customerData).forEach(([key, val]) => {
          const jsonArrays = ['people_cc'];
          if (jsonArrays.includes(key)) {
            form.update(key, val?.join(', '));
          } else {
            form.update(key, val);
          }
        });
        setCustomer(customerData);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    const getFacilityData = async () => {
      try {
        const {
          data: { data: facilityData },
        } = await api.get(`/backend/api/facilities/`);
        setFacilities(facilityData);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    getCustomerData();
    getFacilityData();
  }, [customer?.id]);

  const userCan = useAuthStore(state => state.userCan);

  const userCanDelete = userCan('delete customer');

  const handleDelete = async () => {
    // random id for notification popup
    const id = (Math.random() + 1).toString(36).substring(7);
    try {
      await api.delete(`/backend/api/customers/${customer?.id}`);

      notifications.add({
        title: 'Kunde gelöscht',
        icon: 'check',
        id,
        type: 'success',
        handleClose: () => {
          // eslint-disable-next-line no-console
          console.log('close');
        },
      });
      setTimeout(() => {
        notifications.remove(id);
      }, 2000);
      navigate('/kunden');
    } catch (error) {
      notifications.add({
        title: 'Kunde konnte nicht gelöscht werden',
        details: 'Bitte versuchen Sie es später erneut',
        icon: 'exclamation',
        id,
        type: 'danger',
      });
      setTimeout(() => {
        notifications.remove(id);
      }, 5000);
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  // calculating top position for sticky table header
  useEffect(() => {
    setStickyBarHeight(stickyRef.current?.clientHeight);
  }, [stickyRef.current?.clientHeight]);

  return (
    <Wrapper>
      <Layout
        breadcrumbs={[
          {
            to: '/kunden',
            title: 'Kunden',
          },
        ]}
        title={customer?.name || 'Kunden anlegen'}
      >
        <ReakitForm {...form}>
          <StickyBar ref={stickyRef}>
            <div className={styles.actions}>
              <FormSubmitButton as={Button} className={styles.submit} {...form}>
                Speichern
              </FormSubmitButton>
              {userCanDelete && (
                <Button theme="secondary" variant="link" onClick={handleDelete}>
                  Löschen
                </Button>
              )}
            </div>
          </StickyBar>
          <Container size="lg">
            <br />
            <br />
            <CustomerForm form={form} />
          </Container>
          {articles?.length > 0 && facilities && (
            <>
              <Container size="xl">
                <Heading size="h2">Artikel</Heading>
              </Container>
              <Container size="full">
                {/* hasStickyHeader displays only a fixed Header, hasOnlyBody displays the matching body */}
                <ArticleTable
                  form={form}
                  articles={articles}
                  facilities={facilities}
                  hasStickyHeader
                  stickyBarHeight={stickyBarHeight}
                />
                <ArticleTable
                  form={form}
                  articles={articles}
                  facilities={facilities}
                  hasOnlyBody
                />
              </Container>
            </>
          )}
        </ReakitForm>
      </Layout>
    </Wrapper>
  );
};

export default Page;
